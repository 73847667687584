export enum SessionStorageKeysEnum {
  AccessToken = "access_token",
  MercureToken = "mercure_token",
  RefreshToken = "refresh_token",
  QuizToken = "quiz_token",
  RedirectUrl = "ds-redirectURL",
  UserId = "user_id",
  User = "ds_user",
  InviteToken = "inviteToken",
  InviteEmail = "inviteEmail",
  IsLoggedIn = "isLoggedIn",
  SelectedAccount = "selectedAccount",
  Locale = "locale",
  AssignedUserReport = "assignedUserReport",
  AccountIndexName = "accountIndexName",
  AccountId = "accountId",
  TeamName = "teamName",
  CourseId = "course-id",
  CourseDriver = "course-driver",
  SelectedDynamoGoalDropdown = "selectedDynamoGoalDropdown",
  NavigationTabCode = "navigationTabCode",
  NavigationTabChildCode = "navigationTabChildCode",
  ShowReport = "showReport",
  ShowInviteTemplateSelection = "showInviteTemplateSelection",
  WelcomeModalShown = "welcomeModalShown",
  TeamReportId = "teamReportId",
  TeamReportType = "teamReportType",
  HasFullAccess = "hasFullAccess",
  IsFirstLogin = "isFirstLogin",
  isAnonAssessmentCompleted = "isAnonAssessmentCompleted",
  PrimaryColor = "primaryColor",
  RestrictedDomains = "restrictedDomains",
  VerfiyLinkTypeEnum = "VerfiyLinkTypeEnum",
  TableColumns = "TableColumns",
  CollapsedMenu = "collapsedMenu",
}

export enum LocalStorageKeysEnum {
  IsFirstLogin = "IsFirstLogin",
}

export enum CryptSecretKeysEnum {
  User = "G/_b>P5w#4&=@HX<}Uqu:xwD]Fp9?@$+YuJ`qAb7gf4yw(c@%LZ_k>aBQC3V;p,x<'x6WyL8t;w?!^/UvfBTF2YGR$7#KcQp;_b*qh)}e:,3~",
  SavedUsers = "}0D0`Y<a1'EUqK5IvoSM[£2u]y9NvIZ£Gy~WE?PIo4C]Krk|",
}

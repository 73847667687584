import { loadScript } from "core-lib";

export const loadCoreJavascriptFiles = (environment: any) => {
  loadScript("assets/js/font-awsome.js", {
    async: false
  });
  if (environment.enableClarity) {
    loadScript("assets/js/clarity/clarity-tracking.js", {
      async: true,
      nonce: environment.nonce,
    });
  }

  if (environment.enableHotjar) {
    loadScript("assets/js/hotjar/hotjar-tracking-code.js", {
      async: true,
      nonce: environment.nonce,
    });
  }
  if (environment.intercom.enabled) {
    loadScript("assets/js/intercom/intercom.js", {
      async: true,
    });
  }

  if (environment.production && window.location.hostname !== "localhost") {
    loadScript("assets/js/analytics/googleTagManagerProd.js", {
      async: true,
      nonce: environment.nonce,
    });
  }
};

export const setClarityUserId = (userId: string) => {
  window.clarity("set", "user-id", userId);
};
